<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title">Division & Position</h2>
      </template>
      <template #action-bar>
        <div class="columns">
          <div class="column is-8">
            <div class="level">
              <div class="level-left">
                <b-button
                  class="is-command has-red-text"
                  icon-left="plus"
                  @click="openModalDivision()"
                >
                  New
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #page-content>
        <div id="divisions-table">
          <b-table
            :data="divisions"
            ref="table"
            narrowed
            detailed
            detail-key="id"
            :detail-transition="'fade'"
            :show-detail-icon="showDetailIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
          >
            <b-table-column
              field="id"
              label="No"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.index + 1 }}
            </b-table-column>

            <b-table-column
              field="division_name"
              label="First Name"
              sortable
              v-slot="props"
            >
              {{ props.row.departmentName }}
            </b-table-column>

            <b-table-column
              field="action"
              label="Action"
              width="200"
              v-slot="props"
            >
              <b-icon
                icon="eye"
                custom-size="mdi-18px"
                class="mr-2 ml-2"
                @click.native="props.toggleDetails(props.row)"
              />
              <b-icon
                icon="plus"
                custom-size="mdi-18px"
                class="mr-2 ml-2"
                @click.native="openModalPosition(props.row)"
              />
              <b-icon
                icon="pencil"
                custom-size="mdi-18px"
                class="mr-2 ml-2"
                @click.native="openModalEditDivision(props.row)"
              />
              <b-icon
                icon="delete"
                custom-size="mdi-18px"
                class="mr-2 ml-2"
                @click.native="confirmDeleteDivision(props.row)"
              />
            </b-table-column>

            <template #detail="props" class="has-background-white">
              <!--- desktop only -->
              <div
                class="columns is-multiline is-mobile mr-6 ml-6 pr-6 pl-6 is-flex-desktop is-hidden-mobile detail-row"
                v-for="(position, index) in props.row.positions"
                :key="'desktop-' + position.id"
              >
                <div class="column is-1 is-mobile">
                  {{ props.index + 1 + '.' + (index + 1) }}
                </div>
                <div class="column is-8 is-mobile">
                  {{ position.positionName }}
                </div>

                <div class="column is-3 is-mobile">
                  <b-icon
                    icon="pencil"
                    custom-size="mdi-18px"
                    class="mr-2 ml-2"
                    @click.native="openModalEditPosition(position, props.row)"
                  />
                  <b-icon
                    icon="delete"
                    custom-size="mdi-18px"
                    class="mr-2 ml-2"
                    @click.native="confirmDeletePosition(position, props.row)"
                  />
                </div>
              </div>

              <!--- mobile and tablet --->
              <div
                class="columns is-multiline is-mobile is-flex-mobile is-hidden-desktop detail-row"
                v-for="(position, index) in props.row.positions"
                :key="'mobile-' + position.id"
              >
                <div class="column is-1 is-mobile">
                  {{ props.index + 1 + '.' + (index + 1) }}
                </div>
                <div class="column is-8 is-mobile">
                  {{ position.positionName }}
                </div>
                <div class="column is-3 is-mobile">
                  <b-icon
                    icon="pencil"
                    custom-size="mdi-18px"
                    class="mr-2 ml-2"
                    @click.native="openModalEditPosition(position)"
                  />
                  <b-icon
                    icon="delete"
                    custom-size="mdi-18px"
                    class="mr-2 ml-2"
                  />
                </div>
              </div>
            </template>
          </b-table>
        </div>

        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are You sure?"
          v-if="isModalDivisionOpen"
          @hide="closeModalDivision"
          :is-modal="true"
        >
          <template #page-header>
            <h2 class="page-title">Create Division</h2>
          </template>
          <template #page-content>
            <ValidationObserver
              ref="observerDivision"
              v-slot="{ handleSubmit }"
            >
              <form
                @submit.prevent="handleSubmit(saveDivision)"
                class="is-label-14-px-normal"
                multipart
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <ValidationProvider
                      name="division name"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Division Name"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-input
                          placeholder="Division Name"
                          v-model="formDivision.divisionName"
                        />
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
                <b-button
                  native-type="submit"
                  type="is-primary"
                  expanded
                  :loading="isSavingDivision"
                >
                  Save Changes
                </b-button>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>

        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are You sure?"
          v-if="isModalPositionOpen"
          @hide="closeModalPosition"
          :is-modal="true"
        >
          <template #page-header>
            <h2 class="page-title">Create Position</h2>
          </template>
          <template #page-content>
            <ValidationObserver
              ref="observerPosition"
              v-slot="{ handleSubmit }"
            >
              <form
                @submit.prevent="handleSubmit(savePosition)"
                class="is-label-14-px-normal"
                multipart
              >
                <div class="column is-12">
                  <ValidationProvider
                    name="position name"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Position Name"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        placeholder="Position Name"
                        v-model="formPosition.positionName"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
                <b-button
                  native-type="submit"
                  type="is-primary"
                  expanded
                  :loading="isSavingPosition"
                >
                  Save Changes
                </b-button>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>
      </template>
    </r-page>
  </div>
</template>

<script>
import { showToast } from '../../services/util'
import ApiService from '../../services/common/api.service'

export default {
  data() {
    return {
      showDetailIcon: false,
      useTransition: false,

      divisions: [],

      isModalDivisionOpen: false,
      isLoadingDivision: false,
      isSavingDivision: false,

      formDivision: {
        divisionId: null,
        divisionName: null,
      },

      isSavingPosition: false,
      isModalPositionOpen: false,

      formPosition: {
        positionId: null,
        positionName: null,
        departmentId: null,
      },

      currentPage: 0,
      lastPage: 0,
      perPage: 20,
      isLoadMore: false,
    }
  },
  methods: {
    async loadMore() {
      if (this.currentPage < this.lastPage) {
        this.isLoadMore = true
        await this.fetchDivisionList()
        this.isLoadMore = false
      }
    },
    openModalDivision() {
      this.isModalDivisionOpen = true
    },
    openModalPosition(division) {
      this.formPosition.departmentId = division.id
      this.isModalPositionOpen = true
    },
    openModalEditDivision(division) {
      this.formDivision.divisionId = division.id
      this.formDivision.divisionName = division.departmentName
      this.isModalDivisionOpen = true
    },
    openModalEditPosition(position, division) {
      this.formPosition.positionId = position.id
      this.formPosition.positionName = position.positionName
      this.formPosition.departmentId = division.id
      this.isModalPositionOpen = true
    },
    closeModalDivision() {
      requestAnimationFrame(() => {
        this.$refs.observerDivision.reset()
        this.formDivision.divisionId = null
        this.formDivision.divisionName = null
        this.isModalDivisionOpen = false
      })
    },
    closeModalPosition() {
      requestAnimationFrame(() => {
        this.$refs.observerPosition.reset()
        this.formPosition.positionName = null
        this.formPosition.positionId = null
        this.formPosition.departmentId = null
        this.isModalPositionOpen = false
      })
    },
    async saveDivision() {
      this.isSavingDivision = true
      try {
        if (this.formDivision.divisionId) {
          // update
          let response = await ApiService.put(`/api/space-roketin/department`, {
            id: this.formDivision.divisionId,
            departmentName: this.formDivision.divisionName,
          })
          let divisionIndex = this.divisions.findIndex(
            (d) => d.id === this.formDivision.divisionId
          )
          if (divisionIndex >= 0) {
            this.divisions[divisionIndex].departmentName =
              response.data.data.departmentName
          }
        } else {
          // create
          let response = await ApiService.post(
            `/api/space-roketin/department`,
            {
              departmentName: this.formDivision.divisionName,
            }
          )
          let newDivision = response.data.data
          newDivision.positions = []

          this.divisions = [...this.divisions, newDivision]
        }

        showToast('Saved', 'is-success', 'is-bottom')
        this.closeModalDivision()
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }

      this.isSavingDivision = false
    },

    async savePosition() {
      this.isSavingPosition = true
      try {
        if (this.formPosition.positionId) {
          // edit
          let response = await ApiService.put(
            `/api/space-roketin/position/${this.formPosition.positionId}`,
            {
              positionName: this.formPosition.positionName,
              departmentId: this.formPosition.departmentId,
            }
          )
          // update local state
          let divisionIndex = this.divisions.findIndex(
            (d) => d.id === this.formPosition.departmentId
          )
          if (divisionIndex >= 0) {
            let tempDivision = [...this.divisions]
            let positionIndex = tempDivision[divisionIndex].positions.findIndex(
              (p) => p.id === this.formPosition.positionId
            )
            if (positionIndex >= 0) {
              tempDivision[divisionIndex].positions[
                positionIndex
              ].positionName = response.data.data.positionName
              this.divisions = tempDivision
            }
          }
        } else {
          // create
          let response = await ApiService.post(`/api/space-roketin/position`, {
            positionName: this.formPosition.positionName,
            departmentId: this.formPosition.departmentId,
          })

          // update local state
          let divisionIndex = this.divisions.findIndex(
            (d) => d.id === this.formPosition.departmentId
          )
          if (divisionIndex >= 0) {
            let tempDivision = [...this.divisions]
            tempDivision[divisionIndex].positions.push(response.data.data)
            this.divisions = tempDivision
          }
        }
        showToast('Saved', 'is-success', 'is-bottom')
        this.closeModalPosition()
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
      this.isSavingPosition = false
    },

    confirmDeletePosition(position, division) {
      this.$buefy.dialog.confirm({
        title: 'Delete Position',
        message: `Are you sure you want to <b>delete ${position.positionName} </b> from division <b>${division.departmentName} </b> ? This action cannot be undone.`,
        confirmText: 'Delete Position',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deletePosition(position, division),
      })
    },

    confirmDeleteDivision(division) {
      this.$buefy.dialog.confirm({
        title: 'Delete Division',
        message: `Are you sure you want to <b>delete ${division.departmentName} </b>? This action cannot be undone.`,
        confirmText: 'Delete Division',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteDivision(division),
      })
    },

    async deleteDivision(division) {
      try {
        await ApiService.delete(`/api/space-roketin/department/${division.id}`)

        this.divisions = this.divisions.filter((d) => d.id !== division.id)

        showToast('Division Deleted', 'is-success', 'is-bottom')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
    },
    async deletePosition(position, division) {
      try {
        await ApiService.delete(`/api/space-roketin/position/${position.id}`)
        // update local state
        let divisionIndex = this.divisions.findIndex(
          (d) => d.id === division.id
        )
        if (divisionIndex >= 0) {
          this.divisions[divisionIndex].positions = this.divisions[
            divisionIndex
          ].positions.filter((p) => p.id !== position.id)
        }

        showToast('Position Deleted', 'is-success', 'is-bottom')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
    },

    async fetchDivisionList() {
      this.isLoadingDivision = true
      try {
        let response = await ApiService.get(
          '/api/space-roketin/department/collection',
          {
            perPage: this.perPage,
            page: ++this.currentPage,
          }
        )
        this.divisions = [...this.divisions, ...response.data.data]
        this.lastPage = response.data.lastPage
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
      this.isLoadingDivision = false
    },
  },

  async mounted() {
    await this.fetchDivisionList()
  },
}
</script>

<style lang="scss"></style>
